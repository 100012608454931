import {Question} from "shared";

export const questions: Question[] = [{
  id: 1,
  label: 'Wat doe je?',
  questionTime: 20,
  videoPath: 'assets/videos/inject-3/KIM-Inject3-Intro+S1.mp4',
  timestamp: 121,
  answers: [
    {
      id: 2,
      label: 'Team neemt polshoogte bij slachtoffer.',
      hasExplanation: true,
      explanation: 'kun je je antwoord toelichten?',
      followUpQuestionId: 2,
    },
    {
      id: 6,
      label: 'Team noteert positie op kaart en vervolgt verkenningsmissie',
      hasExplanation: true,
      explanation: 'kun je je antwoord toelichten?',
      followUpQuestionId: 3,
    }
  ]
},
  {
    id: 2,
    videoPath: 'assets/videos/inject-3/KIM-Inject3-S2A.mp4',
    questionTime: 20,
    label: 'Wat adviseer je?',
    timestamp: 56,
    answers: [
      {
        id: 3,
        label: 'Help het slachtoffer en verplaats hem naar een stabiele situatie.',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-3/KIM-Inject3-S3A.mp4'
        }
      },
      {
        id: 4,
        label: 'Ga door met de missie.',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        followUpQuestionId: 3
      }
    ]
  },
  {
    id: 3,
    label: 'Wat doe je?',
    questionTime: 20,
    videoPath: 'assets/videos/inject-3/KIM-Inject3-S2B.mp4',
    timestamp: 48,
    answers: [
      {
        id: 7,
        label: 'Help de gewonden.',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-3/KIM-Inject3-S4A.mp4',
        }
      },
      {
        id: 8,
        label: 'Ga door met de missie.',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-3/KIM-Inject3-S4B.mp4',
        }
      }
    ]
  }
]

export const firstQuestion: number = 1;
