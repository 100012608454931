import {Component} from '@angular/core';
import {AsyncPipe, JsonPipe, NgIf} from "@angular/common";
import {IntroScreenComponent} from "../../intro-screen/intro-screen.component";
import {QuizService} from "../../services/quiz.service";
import {combineLatestWith, map, Observable, tap} from "rxjs";
import {Async, Question} from "shared";
import {ActivatedRoute, NavigationStart, Router, RouterOutlet} from "@angular/router";
import {TopbarComponent} from "../../topbar/topbar.component";
import {
  GroupControllerService,
  GroupDTO,
  InjectControllerService,
  InjectDTO, ModuleControllerService,
  ModuleDTO,
  ResultDTO
} from "mdef-api";
import {GameMapComponent} from "component-library";
import {SignUpFormComponent} from "../../nickname/sign-up-form.component";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import { Location } from '@angular/common';
@UntilDestroy()
@Component({
  selector: 'app-signup-page',
  standalone: true,
  imports: [
    AsyncPipe,
    IntroScreenComponent,
    NgIf,
    RouterOutlet,
    TopbarComponent,
    GameMapComponent,
    SignUpFormComponent,
    JsonPipe
  ],
  templateUrl: './signup-page.component.html',
  styleUrl: './signup-page.component.scss'
})
export class SignupPageComponent {
  question$: Observable<Question | null>;

  selectableInjects: Observable<{ id: number, description: string, playable: boolean }[]>;
  currentStep: number = 1;
  injectFinished: boolean = false;
  groups: Async<Array<GroupDTO>>;
  injects: Async<Array<InjectDTO>>;
  modules: Async<Array<ModuleDTO>>;

  constructor(private quizService: QuizService, private router: Router, injectControllerService: InjectControllerService, private route: ActivatedRoute, groupControllerService: GroupControllerService,
              moduleControllerService: ModuleControllerService, private _location: Location) {
    router.events.pipe(
      untilDestroyed(this)
    ).subscribe((event) => {
      if (!(event instanceof NavigationStart) || event.navigationTrigger === 'popstate') {
        this.injectFinished = this.route.snapshot.queryParams['injectFinished'] !== undefined;
        const queryParams: Partial<ResultDTO> = this.route.snapshot.queryParams;
        this.restoreCurrentStep(queryParams);
      }
    });

    this.question$ = quizService.currentQuestion$;
    this.selectableInjects = injectControllerService.getAllInjects().pipe(
      untilDestroyed(this),
      map(injects => injects.sort((i1, i2) => (i1.id ?? 0) - (i2.id ?? 0))),
      combineLatestWith(this.quizService.currentPlayerDetails),
      map(([injects, currentResultDTO]) => (
        [injects.filter(inject => inject.field === currentResultDTO.field || inject.field === 'ALL'), currentResultDTO] as [InjectDTO[], Partial<ResultDTO>])
      ),
      map(([injects, currentResultDTO]) => {
      const displayInjects: { id: number, description: string, playable: boolean }[] = [];
      //Filter all injects to the only playable for the selected field.
      injects = injects.filter(inject2 => inject2.field === currentResultDTO.field || inject2.field === 'ALL');
      for (let i = 0; i < 5; i++) {
        const inject = injects[i];
        if (inject) {
          // An inject is playable once the player has completed the previous inject. The first inject is always playable.
          const playable: boolean = i === 0 || !!injects[(i - 1)].results?.some(result => result?.nickname === currentResultDTO.nickname);
          displayInjects.push({id: inject.id ?? 0, description: inject.name ?? '', playable});
        } else {
          displayInjects.push({id: 0, description: '', playable: false});
        }
      }
      return displayInjects;
    }));

    groupControllerService
      .findAllGroups(true)
      .subscribe((groups) => (this.groups = groups));
    injectControllerService
      .getAllInjects()
      .subscribe((injects) => (this.injects = injects));
    moduleControllerService
      .getAllModules(true)
      .subscribe((modules) => (this.modules = modules));

      }

  onNickNameFormSubmit($event: Partial<ResultDTO>) {
    this.quizService.playerDetails = $event;
    this.router.navigate([], {
      queryParams: {
        groupId: $event.groupId,
        nickname: $event.nickname,
        moduleId: $event.moduleId
      },
      relativeTo: this.route
    });
    this.currentStep++;
  }

  onFieldClicked(field: string) {
    this.quizService.playerDetails = {field};
    this.router.navigate([], {
      queryParams: {
        field
      },
      queryParamsHandling: "merge",
      relativeTo: this.route
    });
    this.currentStep++;
  }

  onSetInject(injectId: number) {
    this.quizService.playerDetails = {injectId};
    this.router.navigate(['quiz']);
  }

  onPageBack(): void {
    this._location.back();
    this.currentStep -= 1;
  }

  private restoreCurrentStep(queryParams: Partial<ResultDTO>) {
    if (queryParams.field) {
      this.quizService.playerDetails = queryParams;
      this.currentStep = 3;
    } else if (queryParams.moduleId && queryParams.groupId) {
      this.quizService.playerDetails = queryParams;
      this.currentStep = 2;
    } else {
      this.currentStep = 1;
    }
  }
}
