import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Answer, Question } from "shared";
import {CommonModule} from "@angular/common";
import {interval, map, merge, Observable, takeUntil, tap, timer} from "rxjs";
import {ExplanationComponent} from "../explanation/explanation.component";
import { CurrencyInputComponent } from "../currency-input/currency-input.component";


@Component({
  selector: 'app-question-viewer',
  templateUrl: './question-viewer.component.html',
  styleUrl: './question-viewer.component.scss',
  standalone: true,
  imports: [CommonModule, ExplanationComponent, CurrencyInputComponent]
})
export class QuestionViewerComponent {
  timeInPercentageForScaleX$: Observable<number | null> | undefined;
  showExplanation = false;
  givenAnswer?: {answer?: Answer, time: number, givenExplanation?: string};

  @Output() answerClicked = new EventEmitter<{answer?: Answer, time: number, givenExplanation?: string}>();
  @Output() showExplanationVideo = new EventEmitter<string>();

  private _question: Question | undefined | null = undefined

  @Input()
  set question(question: Question | null | undefined) {
    this._question = question;

    const defaultTotalTimeInSec = 20;
    let totalTime = question?.questionTime ? question.questionTime : defaultTotalTimeInSec;
    const percentage = 100 / totalTime;

    const timerTimedOut = timer((totalTime + 3) * 1000 + 200).pipe(tap(() => {
        if (this.question && 'answers' in this.question) {
          const defaultAnswer = this.question.answers.find(answer => answer.defaultAnswer);

          if (defaultAnswer) {
            this.onAnswerClicked(defaultAnswer)
          }
        }
      }
    ))

    this.timeInPercentageForScaleX$ = interval(100).pipe(
      takeUntil(merge(this.answerClicked, timerTimedOut)),
      map(n => (Math.ceil(n * percentage) / 1000)),
      //Should never be triggered, but the 200ms thing is a bit tricky
      map(percentage => percentage > 1 ? 1 : percentage)
    );
  }

  get question(): Question | null | undefined {
    return this._question;
  }

  get isBudgetQuestion() {
    return this._question && 'type' in this._question && this._question.type === 'budget';
  }

  onAnswerClicked(answer: Answer) {
    if (typeof answer.explanation === 'string') {
      this.showExplanation = true;
      this.givenAnswer = {answer, time: Date.now()};
    } else {
      this.answerClicked.next({answer, time: Date.now()});
    }
  }

  onExplanationSubmit($event: string) {
    this.showExplanation = false;
    if (this.givenAnswer) {
      if (this.givenAnswer.answer)
        this.givenAnswer.answer.explanation = this.givenAnswer.answer.label;
      this.answerClicked.next({...this.givenAnswer, givenExplanation: $event});
    } else {
      this.answerClicked.next({time: Date.now(), givenExplanation: $event});
    }
  }
}
