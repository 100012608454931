import {Question} from "shared";

export const questions: Question[] = [{
  id: 1,
  label: 'Geef het bedrag aan dat je wilt spenderen',
  questionTime: 20,
  timestamp: 93,
  videoPath: 'assets/videos/inject-5/KIM-Inject5-Intro+S1.mp4',
  type: 'budget',
  answers: [
    {
      id: 3,
      label: 'Budget medische voorzieningen',
      explanation: 'kun je je antwoord toelichten?',
      followUpQuestionId: 2,
    }, {
      id: 4,
      label: 'Budget medische voorzieningen',
      explanation: 'kun je je antwoord toelichten?',
      followUpQuestionId: 2,
    }]
}, {
  id: 2,
  label: 'Geef het bedrag aan dat je wilt spenderen',
  questionTime: 20,
  timestamp: 31,
  videoPath: 'assets/videos/inject-5/KIM-Inject5-S2A.mp4',
  type: 'budget',
  answers: [
    {
      id: 5,
      label: 'Budget water',
      explanation: 'kun je je antwoord toelichten?',
      followUpQuestionId: 3,
    }, {
      id: 6,
      explanation: 'kun je je antwoord toelichten?',
      label: 'Budget water',
      followUpQuestionId: 3,
    }]
}, {
  id: 3,
  label: 'Geef het bedrag aan dat je wilt spenderen',
  questionTime: 20,
  timestamp: 19,
  videoPath: 'assets/videos/inject-5/KIM-Inject5-S3A.mp4',
  type: 'budget',
  answers: [
    {
      id: 8,
      label: 'Budget voedsel',
      hasExplanation: true,
      // currencyAmountQuestion: true,
      explanation: 'kun je je antwoord toelichten?',
      followUpQuestionId: 4
    },
    {
      id: 9,
      label: 'Budget voedsel',
      hasExplanation: true,
      explanation: 'kun je je antwoord toelichten?',
      followUpQuestionId: 5
    },
    {
      id: 10,
      label: 'Budget voedsel',
      hasExplanation: true,
      explanation: 'kun je je antwoord toelichten?',
      followUpQuestionId: 6
    }
  ]
}, {
  id: 4,
  label: 'Wat doe je',
  questionTime: 20,
  timestamp: 14,
  videoPath: 'assets/videos/inject-5/KIM-Inject5-S4A.mp4',
  answers: [
    {
      id: 12,
      label: 'Je verteld de bottelier dat zij zich niet moet bemoeien met je keuze.',
      hasExplanation: true,
      explanation: 'kun je je antwoord toelichten?',
      intermediateResult: {
        videoPath: 'assets/videos/inject-5/KIM-Inject5-S5A.mp4',
        nextIntermediateResult: {
          videoPath: 'assets/videos/KIM-Outro-Inject2-5.mp4',
        }
      },
    },
    {
      id: 13,
      label: 'Je geeft toe dat je eerst informatie had moeten inwinnen maar je kan niets meer terugdraaien.',
      hasExplanation: true,
      intermediateResult: {
        videoPath: 'assets/videos/inject-5/KIM-Inject5-S5B.mp4',
        nextIntermediateResult: {
          videoPath: 'assets/videos/KIM-Outro-Inject2-5.mp4',
        }
      },
      explanation: 'kun je je antwoord toelichten?',
    }]
}, {
  id: 5,
  label: 'Wat doe je',
  questionTime: 20,
  timestamp: 16,
  videoPath: 'assets/videos/inject-5/KIM-Inject5-S4B.mp4',
  answers: [
    {
      id: 14,
      label: 'Je verteld de bottelier dat hij zich niet moet bemoeien met je keuze.',
      hasExplanation: true,
      intermediateResult: {
        videoPath: 'assets/videos/inject-5/KIM-Inject5-S5A.mp4',
        nextIntermediateResult: {
          videoPath: 'assets/videos/KIM-Outro-Inject2-5.mp4',
        }
      },
      explanation: 'kun je je antwoord toelichten?',
    },
    {
      id: 15,
      label: 'Je geeft toe dat je eerst informatie had moeten inwinnen maar je kan niets meer terugdraaien.',
      hasExplanation: true,
      intermediateResult: {
        videoPath: 'assets/videos/inject-5/KIM-Inject5-S5B.mp4',
        nextIntermediateResult: {
          videoPath: 'assets/videos/KIM-Outro-Inject2-5.mp4',
        }
      },
      explanation: 'kun je je antwoord toelichten?',
    }]
}, {
  id: 6,
  label: 'Wat doe je',
  questionTime: 20,
  timestamp: 18,
  videoPath: 'assets/videos/inject-5/KIM-Inject5-S4C.mp4',
  answers: [
    {
      id: 16,
      label: 'Je verteld de bottelier dat hij zich niet moet bemoeien met je keuze.',
      hasExplanation: true,
      intermediateResult: {
        videoPath: 'assets/videos/inject-5/KIM-Inject5-S5A.mp4',
        nextIntermediateResult: {
          videoPath: 'assets/videos/KIM-Outro-Inject2-5.mp4',
        }
      },
      explanation: 'kun je je antwoord toelichten?',
    },
    {
      id: 17,
      label: 'Je geeft toe dat je eerst informatie had moeten inwinnen maar je kan niets meer terugdraaien.',
      hasExplanation: true,
      intermediateResult: {
        videoPath: 'assets/videos/inject-5/KIM-Inject5-S5B.mp4',
        nextIntermediateResult: {
          videoPath: 'assets/videos/KIM-Outro-Inject2-5.mp4',
        }
      },
      explanation: 'kun je je antwoord toelichten?',
    }]
}, {
  id: 7,
  label: 'Wat doe je',
  questionTime: 20,
  timestamp: 18,
  videoPath: 'assets/videos/inject-5/KIM-Inject5-S4C.mp4',
  answers: [
    {
      id: 19,
      label: 'Je verteld de bottelier dat zij zich niet moet bemoeien met je keuze.',
      hasExplanation: true,
      intermediateResult: {
        videoPath: 'assets/videos/inject-5/KIM-Inject5-S5A.mp4',
        nextIntermediateResult: {
          videoPath: 'assets/videos/KIM-Outro-Inject2-5.mp4',
        }
      },
      explanation: 'kun je je antwoord toelichten?',
    },
    {
      id: 20,
      label: 'Je geeft toe dat je eerst informatie had moeten inwinnen maar je kan niets meer terugdraaien.',
      hasExplanation: true,
      intermediateResult: {
        videoPath: 'assets/videos/inject-5/KIM-Inject5-S5B.mp4',
        nextIntermediateResult: {
          videoPath: 'assets/videos/KIM-Outro-Inject2-5.mp4',
        }
      },
      explanation: 'kun je je antwoord toelichten?',
    }]
}];

export const firstQuestion: number = 1;
