import {Question} from "shared";

export const questions: Question[] = [
    {
      id: 1,
      label: 'Wat moeten we nu doen?',
      questionTime: 20,
      videoPath: 'assets/videos/inject-1/KIM-Inject1-Intro+S1.mp4',
      timestamp: 112,
      answers: [
        {
          id: 2,
          label: 'Ik licht de CDT in en wacht op orders',
          hasExplanation: true,
          explanation: 'kun je je antwoord toelichten?',
          followUpQuestionId: 2
        },
        {
          id: 6,
          label: 'Ik blijf bij mijn orders: De RDV met Zr Ms Den Helder',
          hasExplanation: true,
          explanation: 'kun je je antwoord toelichten?',
          followUpQuestionId: 3
        },
        {
          id: 10,
          label: 'Ik verleg mijn koers naar het ongeidentificeerde contact',
          hasExplanation: true,
          explanation: 'kun je je antwoord toelichten?',
          followUpQuestionId: 4,
        }
      ]
    },
  {
    id: 2,
    label: 'Wat adviseer je?',
    questionTime: 20,
    videoPath: 'assets/videos/inject-1/KIM-Inject1-S2A.mp4',
    timestamp: 111,
    answers: [
      {
        id: 3,
        label: 'Je adviseert toch naar de RDV te gaan.',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-1/KIM-Inject1-S3A.mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/inject-1/KIM-Inject1-S6-Outro.mp4',
          }
        },
      },
      {
        id: 4,
        label: 'Je adviseert vaart te minderen en te observeren.',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-1/KIM-Inject1-S3B.mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/inject-1/KIM-Inject1-S6-Outro.mp4',
          }
        },
      },
      {
        id: 5,
        label: 'Je adviseert om alvast rollen op post te halen.',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-1/KIM-Inject1-S3C.mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/inject-1/KIM-Inject1-S6-Outro.mp4',
          }
        },
      }
    ]
  },
  {
    id: 3,
    videoPath: 'assets/videos/inject-1/KIM-Inject1-S2B.mp4',
    questionTime: 20,
    label: 'Wat is uw vervolgactie?',
    timestamp: 61,
    answers: [
      {
        id: 7,
        label: 'Je informeert de CDT over afwezigheid.',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-1/KIM-Inject1-S4A.mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/inject-1/KIM-Inject1-S6-Outro.mp4',
          }
        },
      },
      {
        id: 8,
        label: 'Je besluit om toch naar Zr Ms Den Helder te gaan.',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-1/KIM-Inject1-S4B.mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/inject-1/KIM-Inject1-S6-Outro.mp4',
          }
        },
      },
      {
        id: 9,
        label: 'Je informeert de CDT en adviseert om te gaan helpen.',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-1/KIM-Inject1-S4C.mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/inject-1/KIM-Inject1-S6-Outro.mp4',
          }
        },
      }
    ]
  },
  {
    id: 4,
    label: 'Hoe moet het verder?',
    questionTime: 20,
    videoPath: 'assets/videos/inject-1/KIM-Inject1-S2C.mp4',
    timestamp: 26,
    answers: [
      {
        id: 11,
        label: 'Je stelt voor om te gaan helpen.',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-1/KIM-Inject1-S5A.mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/inject-1/KIM-Inject1-S6-Outro.mp4',
          }
        },
      },
      {
        id: 12,
        label: 'Je ziet in dat je met de missie verder moet.',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-1/KIM-Inject1-S5B.mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/inject-1/KIM-Inject1-S6-Outro.mp4',
          }
        },
      },
      {
        id: 13,
        label: 'Je adviseert om met een RHIB poolshoogte te nemen.',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-1/KIM-Inject1-S5C.mp4',
          nextIntermediateResult: {
            videoPath: 'assets/videos/inject-1/KIM-Inject1-S6-Outro.mp4',
          }
        },
      }
    ]
  }
]

export const firstQuestion: number = 1;
