import { Question } from "shared";

export const questions: Question[] = [{
  id: 1,
  label: 'Wat doe je?',
  questionTime: 20,
  timestamp: 91,
  videoPath: 'assets/videos/inject-10/KIM-Inject10-Intro+S1.mp4',
  answers: [
    {
      id: 2,
      label: 'Je blijft op de uitgifte plek.',
      hasExplanation: true,
      explanation: 'kun je je antwoord toelichten?',
      followUpQuestionId: 4
    }, {
      id: 3,
      label: 'Je trekt je terug naar het schip.',
      hasExplanation: true,
      explanation: 'kun je je antwoord toelichten?',
      followUpQuestionId: 7
    }
  ]
}, {
  id: 4,
  label: 'Gezien de aangeleverde argumenten zou je een andere keuze maken',
  questionTime: 20,
  videoPath: 'assets/videos/inject-10/KIM-Inject10-S2A.mp4',
  timestamp: 23,
  answers: [
    {
      id: 5,
      label: 'Ja',
      hasExplanation: true,
      explanation: 'kun je je antwoord toelichten?',
      followUpQuestionId: 9
    },
    {
      id: 6,
      label: 'Nee',
      hasExplanation: true,
      explanation: 'kun je je antwoord toelichten?',
      followUpQuestionId: 9
    }
  ]
}, {
  id: 7,
  label: 'Gezien de aangeleverde argumenten zou je een andere keuze maken',
  questionTime: 20,
  timestamp: 23,
  videoPath: 'assets/videos/inject-10/KIM-Inject10-S2B.mp4',
  answers: [
    {
      id: 8,
      label: 'Ja',
      hasExplanation: true,
      explanation: 'kun je je antwoord toelichten?',
      followUpQuestionId: 9
    },
    {
      id: 9,
      label: 'Nee',
      hasExplanation: true,
      explanation: 'kun je je antwoord toelichten?',
      followUpQuestionId: 9
    }
  ]
},{
  id: 9,
  label: "Hoe reageer je?",
  questionTime: 20,
  timestamp: 15,
  videoPath: 'assets/videos/inject-10/KIM-Inject10-S3A.mp4',
  answers: [
    {
      id: 10,
      label: 'Spreek de club toe.',
      hasExplanation: true,
      explanation: 'kun je je antwoord toelichten?',
      intermediateResult: {
        videoPath: 'assets/videos/inject-10/KIM-Inject10-S4A.mp4',
        nextIntermediateResult: {
          videoPath: 'assets/videos/KIM-Outro-Inject7-10.mp4'
        }
      }
    },
    {
      id: 11,
      label: 'Laat de situatie voor wat het is.',
      hasExplanation: true,
      explanation: 'kun je je antwoord toelichten?',
      intermediateResult: {
        videoPath: 'assets/videos/inject-10/KIM-Inject10-S4A.mp4',
        nextIntermediateResult: {
          videoPath: 'assets/videos/KIM-Outro-Inject7-10.mp4'
        }
      }
    }
  ]
}
];

export const firstQuestion: number = 1;
