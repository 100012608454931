import {Question} from "shared";

export const questions: Question[] = [
  {
    id: 1,
    label: 'Wat doe je?',
    questionTime: 20,
    videoPath: 'assets/videos/inject-2/KIM-Inject2-Intro+S1.mp4',
    timestamp: 120,
    answers: [
      {
        id: 2,
        label: 'Ik spreek de roerganger aan op haar gedrag en stuur aan op een oplossing.',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        followUpQuestionId: 2
      },
      {
        id: 6,
        label: 'Ik toon empathie en help de roerganger met haar werk',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        followUpQuestionId: 3
      }
    ]
  },
  {
    id: 2,
    label: 'Wat adviseer je?',
    questionTime: 20,
    videoPath: 'assets/videos/inject-2/KIM-Inject2-S2A.mp4',
    timestamp: 48,
    answers: [
      {
        id: 3,
        label: 'Ga het gesprek aan met de roerganger om de werkrelatie te herstellen.',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-2/KIM-Inject2-S3A.mp4',
        }
      },
      {
        id: 4,
        label: 'Ga later het gesprek aan met de roerganger en geef prioriteit aan de missie.',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-2/KIM-Inject2-S3B.mp4',
        }
      }
    ]
  },
  {
    id: 3,
    label: 'Wat doe je?',
    questionTime: 20,
    videoPath: 'assets/videos/inject-2/KIM-Inject2-S2B.mp4',
    timestamp: 53,
    answers: [
      {
        id: 7,
        label: 'Voer de taak alsnog zelf uit.',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-2/KIM-Inject2-S3C.mp4',
        }
      },
      {
        id: 8,
        label: 'Spreek de werkdruk uit en geef aan dat je het als team wil oplossen.',
        hasExplanation: true,
        explanation: 'kun je je antwoord toelichten?',
        intermediateResult: {
          videoPath: 'assets/videos/inject-2/KIM-Inject2-S3D.mp4',
        }
      }
    ]
  }
]

export const firstQuestion: number = 1;
